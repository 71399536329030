import React, {useState} from "react";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import styled from "styled-components";
import LayoutB from "../components/LayoutB";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-width: 100%;
  height: 100%;
`;

const Title = styled.div`
  margin-bottom: 25px;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 0.9rem;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: 768px) {
    margin-bottom: 30px;
    font-size: 1rem;
    line-height: 1rem;
  }

  @media (min-width: 1366px) {
    margin-bottom: 35px;
    font-size: 1.1rem;
    line-height: 1.1rem;
  }

  @media (min-width: 1440px) {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }

  @media (min-width: 1920px) {
    margin-bottom: 40px;
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
`;

const Marcas = styled.div`
  display: grid;
  justify-content: center;
  gap: 15px;
  grid-template-columns: auto auto auto auto;

  @media (min-width: 768px) {
    gap: 20px;
    grid-template-columns: auto auto auto auto auto auto;
  }
`;

const Marca = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  background-color: white;
  overflow: hidden;
  z-index: 1;

  @media (min-width: 768px) {
    width: 70px;
    height: 70px;
  }

  @media (min-width: 1280px) {
    width: 75px;
    height: 75px;
  }

  @media (min-width: 1280px) {
    width: 80px;
    height: 80px;
  }

  @media (min-width: 1366px) {
    width: 85px;
    height: 85px;
  }

  @media (min-width: 1440px) {
    width: 90px;
    height: 90px;
  }

  @media (min-width: 1920px) {
    width: 95px;
    height: 95px;
  }
`;

const MarcasPage = () => {
  const data = useStaticQuery(graphql`
    {
      dataJson {
        marcas {
          id
          name
          logo {
            childImageSharp {
              gatsbyImageData(
                width: 95
                quality: 80
              )
            }
          }
          logoBw {
            childImageSharp {
              gatsbyImageData(
                width: 95
                quality: 80
              )
            }
          }
        }
      }
    }
  `);
  const marcas = data.dataJson.marcas;
  const [brand, setBrand] = useState(null);

  return (
    <LayoutB pageTitle="Marcas que confían en nosotros">
      <Container>
        <Title>Marcas que confían en nosotros</Title>
        <Marcas>
          {marcas.map(marca => {
            return (
              <Marca key={marca.id}>
                <GatsbyImage
                  image={getImage(marca.id === brand ? marca.logo : marca.logoBw)}
                  alt={marca.name}
                  onMouseEnter={() => setBrand(marca.id)}
                  onMouseLeave={() => setBrand(null)}
                />
              </Marca>
            );
          })}
        </Marcas>
      </Container>
    </LayoutB>
  );
};

export default MarcasPage;